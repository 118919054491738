<template>
	<div
		v-if="endpointUrl && !(!state && success)"
		class="c-subscription-form print:hidden"
	>
		<!-- Subscription button -->
		<div v-if="!state" class="flex justify-center items-center mx-auto">
			<BaseButton type="extra" @click="onBellClick">
				<template #icon>
					<SvgBellIcon class="w-16 h-16 text-white" />
				</template>
				Følg denne side
			</BaseButton>
		</div>

		<!-- Form -->
		<AxiosForm
			v-else-if="!success"
			:action="endpointUrl"
			method="post"
			use-native-form-data-on-post
			:class="[
				'relative',
				'mx-auto w-5col >=768:w-12col',
				'bg-extra rounded-lg py-md px-sm',
			]"
			@submit="onSubmit"
			@complete="onComplete"
			@error="onError"
		>
			<button
				role="button"
				:class="[
					'absolute top-12 right-12',
					'w-40 h-40 rounded-full',
					'flex justify-center items-center',
				]"
				@click="onClear"
			>
				<SvgCloseIcon class="text-white w-10" />
			</button>

			<div
				:class="[
					'flex flex-col items-center gap-y-sm',
					'>=768:w-6col >=1280:w-4col text-center text-white mx-auto',
				]"
			>
				<BaseH1 tag="h2" v-text="'Følg denne side'" />
				<p v-if="error" key="error" role="alert" v-html="error"></p>
				<p v-else>
					Indtast din e-mail for at følge siden “{{ pageTitle }}”. Når
					der sker ændringer på siden, får du besked via e-mail
				</p>
			</div>

			<div class="space-y-md mt-md mx-auto text-white >=768:w-8col">
				<template v-if="fieldsByType.hidden">
					<input
						v-for="(field, index) in fieldsByType.hidden"
						:key="`hidden-${index}`"
						:name="field.name"
						:value="field.value"
						type="hidden"
					/>
				</template>

				<div
					class="
						flex
						<768:flex-col
						flex-wrap
						gap-x-layout-gutter gap-y-md
					"
				>
					<div v-if="fieldsByName.name" class="flex-1">
						<label
							:for="`${fieldsByName.name.name}-${_uid}`"
							class="sr-only"
							v-text="fieldsByName.name.label"
						></label>

						<input
							:id="`${fieldsByName.name.name}-${_uid}`"
							v-model="fieldsByName.name.value"
							type="text"
							:name="fieldsByName.name.name"
							:placeholder="fieldsByName.name.placeholder"
							:required="fieldsByName.name.required"
							:class="[
								'text-h4 py-sm px-md text-text',
								'w-full bg-white rounded-md',
							]"
						/>
					</div>

					<div v-if="fieldsByName.email" class="flex-1">
						<label
							:for="`${fieldsByName.email.name}-${_uid}`"
							class="sr-only"
							v-text="fieldsByName.email.label"
						></label>

						<input
							:id="`${fieldsByName.email.name}-${_uid}`"
							v-model="fieldsByName.email.value"
							type="email"
							:name="fieldsByName.email.name"
							:placeholder="fieldsByName.email.placeholder"
							:required="fieldsByName.email.required"
							:class="[
								'text-h4 py-sm px-md text-text',
								'w-full bg-white rounded-md',
							]"
						/>
					</div>

					<FormCheckbox
						v-if="fieldsByName.disclaimer"
						class="w-full flex-shrink-0"
						v-bind="{
							...fieldsByName.disclaimer,
						}"
						on-dark-background
					/>
				</div>

				<BaseButton
					v-if="fieldsByName.submit"
					role="submit"
					:aria-disabled="loading"
					:class="['mx-auto']"
					v-text="fieldsByName.submit.label"
				/>
			</div>
		</AxiosForm>

		<!-- Success -->
		<div
			v-else
			:class="[
				'relative',
				'mx-auto w-5col >=768:w-12col',
				'bg-extra rounded-lg py-lg px-sm',
			]"
		>
			<div
				:class="[
					'flex flex-col items-center gap-y-sm',
					'>=768:w-6col text-center text-white mx-auto',
				]"
			>
				<button
					role="button"
					:class="[
						'absolute top-12 right-12',
						'w-40 h-40 rounded-full',
						'flex justify-center items-center',
					]"
					@click="onClear"
				>
					<SvgCloseIcon class="text-white w-10" />
				</button>

				<BaseH1 tag="h2" v-text="'Tak for din interesse'" />
				<p>
					Du modtager snart en e-mail, hvor du bliver bedt om at
					bekræfte, at du vil følge denne side. Du er først tilmeldt,
					når du har klikket på linket. Husk at kigge i din
					spam-mappe, hvis mailen ikke dukker op.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import AxiosForm from '~/citi-baseline/components/AxiosForm';
import FormCheckbox from '~/components/shared/Form/FormCheckbox';
import SvgBellIcon from '~/assets/svgs/bell-icon.svg?inline';
import SvgCloseIcon from '~/assets/svgs/close-icon.svg?inline';

export default {
	name: 'SubscriptionForm',

	components: {
		AxiosForm,
		FormCheckbox,
		SvgBellIcon,
		SvgCloseIcon,
	},

	props: {
		endpointUrl: {
			type: String,
			default: '',
		},

		fields: {
			type: Array,
			default: () => [],
		},

		pageTitle: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			state: '',
			error: null,
			loading: false,
			success: false,
		};
	},

	computed: {
		fieldsByType() {
			return this.fields.reduce((acc, field) => {
				const type = (field.type || 'undefined').toLowerCase();
				acc[type] = acc[type] || [];
				acc[type].push(field);
				return acc;
			}, {});
		},

		fieldsByName() {
			return this.fields.reduce((acc, field) => {
				acc[field.name] = field;
				return acc;
			}, {});
		},
	},

	methods: {
		onBellClick() {
			this.state = 'form';
		},

		onClear() {
			this.state = '';
		},

		onSubmit() {
			this.loading = true;
			this.error = null;
		},
		onComplete() {
			this.loading = false;
			this.success = true;
		},
		onError({ response }) {
			this.error = `<strong>Der skete en fejl.</strong><br/>Server besked: "(${response.status}) ${response.statusText}"`;
			this.loading = false;
		},
	},
};
</script>
