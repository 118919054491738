var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"d-content-page-custom"},[_c(_vm.PageHero,_vm._b({tag:"Component"},'Component',Object.assign({}, _vm.header, {breadcrumb: _vm.breadcrumb}),false)),_vm._v(" "),_c('div',{staticClass:"mx-layout-margin relative mb-lg"},[(_vm.contentElements.length && _vm.showToc)?_c('PageAside',{staticClass:"<1024:hidden",attrs:{"show-longread":_vm.showToc}}):_vm._e(),_vm._v(" "),_c(_vm.BlockList,{tag:"Component",staticClass:"w-full",attrs:{"block-list":_vm.contentElements}})],1),_vm._v(" "),(_vm.activateFeedback)?_c('FeedbackForm',{staticClass:"mb-lg"}):_vm._e(),_vm._v(" "),(_vm.form)?_c('SubscriptionForm',_vm._b({staticClass:"mb-lg",attrs:{"page-title":_vm.header.title}},'SubscriptionForm',_vm.form,false)):_vm._e(),_vm._v(" "),(
			_vm.showRelatedPages &&
			_vm.showRelatedPages.pages &&
			_vm.showRelatedPages.pages.length
		)?_c('RelatedPages',{attrs:{"title":_vm.showRelatedPages.title,"items":_vm.showRelatedPages.pages}}):_vm._e(),_vm._v(" "),(
			_vm.showMorePages &&
			_vm.showMorePages.pages &&
			_vm.showMorePages.pages.length
		)?_c('RelatedPages',{attrs:{"title":_vm.showMorePages.title,"items":_vm.showMorePages.pages}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }