var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.endpointUrl && !(!_vm.state && _vm.success))?_c('div',{staticClass:"c-subscription-form print:hidden"},[(!_vm.state)?_c('div',{staticClass:"flex justify-center items-center mx-auto"},[_c('BaseButton',{attrs:{"type":"extra"},on:{"click":_vm.onBellClick},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgBellIcon',{staticClass:"w-16 h-16 text-white"})]},proxy:true}],null,false,990668387)},[_vm._v("\n\t\t\tFølg denne side\n\t\t")])],1):(!_vm.success)?_c('AxiosForm',{class:[
			'relative',
			'mx-auto w-5col >=768:w-12col',
			'bg-extra rounded-lg py-md px-sm' ],attrs:{"action":_vm.endpointUrl,"method":"post","use-native-form-data-on-post":""},on:{"submit":_vm.onSubmit,"complete":_vm.onComplete,"error":_vm.onError}},[_c('button',{class:[
				'absolute top-12 right-12',
				'w-40 h-40 rounded-full',
				'flex justify-center items-center' ],attrs:{"role":"button"},on:{"click":_vm.onClear}},[_c('SvgCloseIcon',{staticClass:"text-white w-10"})],1),_vm._v(" "),_c('div',{class:[
				'flex flex-col items-center gap-y-sm',
				'>=768:w-6col >=1280:w-4col text-center text-white mx-auto' ]},[_c('BaseH1',{attrs:{"tag":"h2"},domProps:{"textContent":_vm._s('Følg denne side')}}),_vm._v(" "),(_vm.error)?_c('p',{key:"error",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.error)}}):_c('p',[_vm._v("\n\t\t\t\tIndtast din e-mail for at følge siden “"+_vm._s(_vm.pageTitle)+"”. Når\n\t\t\t\tder sker ændringer på siden, får du besked via e-mail\n\t\t\t")])],1),_vm._v(" "),_c('div',{staticClass:"space-y-md mt-md mx-auto text-white >=768:w-8col"},[(_vm.fieldsByType.hidden)?_vm._l((_vm.fieldsByType.hidden),function(field,index){return _c('input',{key:("hidden-" + index),attrs:{"name":field.name,"type":"hidden"},domProps:{"value":field.value}})}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\tflex\n\t\t\t\t\t<768:flex-col\n\t\t\t\t\tflex-wrap\n\t\t\t\t\tgap-x-layout-gutter gap-y-md\n\t\t\t\t"},[(_vm.fieldsByName.name)?_c('div',{staticClass:"flex-1"},[_c('label',{staticClass:"sr-only",attrs:{"for":((_vm.fieldsByName.name.name) + "-" + _vm._uid)},domProps:{"textContent":_vm._s(_vm.fieldsByName.name.label)}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsByName.name.value),expression:"fieldsByName.name.value"}],class:[
							'text-h4 py-sm px-md text-text',
							'w-full bg-white rounded-md' ],attrs:{"id":((_vm.fieldsByName.name.name) + "-" + _vm._uid),"type":"text","name":_vm.fieldsByName.name.name,"placeholder":_vm.fieldsByName.name.placeholder,"required":_vm.fieldsByName.name.required},domProps:{"value":(_vm.fieldsByName.name.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fieldsByName.name, "value", $event.target.value)}}})]):_vm._e(),_vm._v(" "),(_vm.fieldsByName.email)?_c('div',{staticClass:"flex-1"},[_c('label',{staticClass:"sr-only",attrs:{"for":((_vm.fieldsByName.email.name) + "-" + _vm._uid)},domProps:{"textContent":_vm._s(_vm.fieldsByName.email.label)}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsByName.email.value),expression:"fieldsByName.email.value"}],class:[
							'text-h4 py-sm px-md text-text',
							'w-full bg-white rounded-md' ],attrs:{"id":((_vm.fieldsByName.email.name) + "-" + _vm._uid),"type":"email","name":_vm.fieldsByName.email.name,"placeholder":_vm.fieldsByName.email.placeholder,"required":_vm.fieldsByName.email.required},domProps:{"value":(_vm.fieldsByName.email.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fieldsByName.email, "value", $event.target.value)}}})]):_vm._e(),_vm._v(" "),(_vm.fieldsByName.disclaimer)?_c('FormCheckbox',_vm._b({staticClass:"w-full flex-shrink-0",attrs:{"on-dark-background":""}},'FormCheckbox',Object.assign({}, _vm.fieldsByName.disclaimer),false)):_vm._e()],1),_vm._v(" "),(_vm.fieldsByName.submit)?_c('BaseButton',{class:['mx-auto'],attrs:{"role":"submit","aria-disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.fieldsByName.submit.label)}}):_vm._e()],2)]):_c('div',{class:[
			'relative',
			'mx-auto w-5col >=768:w-12col',
			'bg-extra rounded-lg py-lg px-sm' ]},[_c('div',{class:[
				'flex flex-col items-center gap-y-sm',
				'>=768:w-6col text-center text-white mx-auto' ]},[_c('button',{class:[
					'absolute top-12 right-12',
					'w-40 h-40 rounded-full',
					'flex justify-center items-center' ],attrs:{"role":"button"},on:{"click":_vm.onClear}},[_c('SvgCloseIcon',{staticClass:"text-white w-10"})],1),_vm._v(" "),_c('BaseH1',{attrs:{"tag":"h2"},domProps:{"textContent":_vm._s('Tak for din interesse')}}),_vm._v(" "),_c('p',[_vm._v("\n\t\t\t\tDu modtager snart en e-mail, hvor du bliver bedt om at\n\t\t\t\tbekræfte, at du vil følge denne side. Du er først tilmeldt,\n\t\t\t\tnår du har klikket på linket. Husk at kigge i din\n\t\t\t\tspam-mappe, hvis mailen ikke dukker op.\n\t\t\t")])],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }