<template>
	<span
		class="c-form-checkbox"
		:class="onDarkBackground && 'c-form-checkbox--on-dark-background'"
	>
		<input
			:id="computedId"
			type="checkbox"
			class="cursor-pointer"
			v-bind="$attrs"
			:checked="internallyChecked"
			v-on="computedListeners"
		/>
		<label
			v-if="label"
			:for="computedId"
			class="s-rich-text s-rich-text--monotone pl-12 cursor-pointer"
		>
			<span v-html="label"></span>
		</label>
	</span>
</template>

<script>
export default {
	name: 'FormCheckbox',
	inheritAttrs: false,

	model: {
		prop: 'checked',
		event: 'change',
	},

	props: {
		id: {
			type: String,
			default: '',
		},
		checked: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: '',
		},
		onDarkBackground: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			internallyChecked: this.checked,
		};
	},

	computed: {
		computedId() {
			return this.id || `c-form-checkbox-${this._uid}`;
		},
		computedListeners() {
			return { ...this.$listeners, change: this.onChange };
		},
	},

	watch: {
		checked(checked) {
			this.internallyChecked = checked;
		},
	},

	methods: {
		onChange(e) {
			this.internallyChecked = e.target.checked;
			this.$emit('change', this.internallyChecked);
		},
	},
};
</script>

<style lang="postcss">
.c-form-checkbox {
	@apply inline-flex items-baseline;

	& input {
		@apply appearance-none relative inline-block w-16 h-16 transform translate-y-2 flex-shrink-0 cursor-pointer;
		@apply border-2 border-primary-button overflow-hidden;
		@apply duration-300 ease-smooth-out;
		border-radius: 2px;
		font-size: 0 !important;

		&:after {
			content: '';
			@apply duration-300 ease-smooth-out;
			@apply absolute inline-block w-full h-full;
			@apply opacity-0 pointer-events-none;
			background: url('~assets/svgs/checkmark-icon--white.svg');
			background-repeat: no-repeat;
			background-position: bottom center;
			background-size: 10px 8px;
		}

		&:checked:after {
			@apply bg-primary-button opacity-100;
			background-position: center;
		}
	}
}

.c-form-checkbox--on-dark-background {
	& input {
		@apply border-white;

		&:after {
			background: url('~assets/svgs/checkmark-icon.svg');
			background-repeat: no-repeat;
			background-position: bottom center;
			background-size: 10px 8px;
		}

		&:checked:after {
			@apply bg-white opacity-100;
			background-position: center;
		}
	}
}
</style>
