<template>
	<article class="d-content-page-custom">
		<Component :is="PageHero" v-bind="{ ...header, breadcrumb }" />

		<div class="mx-layout-margin relative mb-lg">
			<PageAside
				v-if="contentElements.length && showToc"
				class="<1024:hidden"
				:show-longread="showToc"
			/>
			<Component
				:is="BlockList"
				:block-list="contentElements"
				class="w-full"
			/>
		</div>

		<FeedbackForm v-if="activateFeedback" class="mb-lg" />

		<SubscriptionForm
			v-if="form"
			v-bind="form"
			:page-title="header.title"
			class="mb-lg"
		/>

		<RelatedPages
			v-if="
				showRelatedPages &&
				showRelatedPages.pages &&
				showRelatedPages.pages.length
			"
			:title="showRelatedPages.title"
			:items="showRelatedPages.pages"
		/>

		<RelatedPages
			v-if="
				showMorePages &&
				showMorePages.pages &&
				showMorePages.pages.length
			"
			:title="showMorePages.title"
			:items="showMorePages.pages"
		/>
	</article>
</template>

<script>
import PageAside from '~/components/shared/PageAside';
import RelatedPages from '~/components/shared/RelatedPages';
import FeedbackForm from '~/components/shared/FeedbackForm';
import SubscriptionForm from '~/components/shared/SubscriptionForm';

const MainPageHero = () => import('~/components/main/PageHero');
const SubsitePageHero = () => import('~/components/subsite/PageHero');
const MainBlockList = () => import('~/components/main/BlockList');
const SubsiteBlockList = () => import('~/components/subsite/BlockList');

export default {
	name: 'SubsiteContentPageCustom',

	components: {
		PageAside,
		RelatedPages,
		FeedbackForm,
		SubscriptionForm,
	},

	inheritAttrs: false,

	props: {
		header: {
			type: Object,
			default: () => ({}),
		},
		breadcrumb: {
			type: Array,
			required: false,
		},
		contentElements: {
			type: Array,
			default: () => [],
		},
		showToc: {
			type: Boolean,
			default: false,
		},
		showMorePages: {
			type: Object,
			required: false,
		},
		showRelatedPages: {
			type: Object,
			required: false,
		},
		activateFeedback: {
			type: Boolean,
			default: false,
		},
		form: {
			type: Object,
			default: null,
		},
	},

	computed: {
		PageHero() {
			const map = {
				main: MainPageHero,
				subsite: SubsitePageHero,
			};
			return map[this.$store.state.site.alias] || MainPageHero;
		},
		BlockList() {
			const map = {
				main: MainBlockList,
				subsite: SubsiteBlockList,
			};
			return map[this.$store.state.site.alias] || MainBlockList;
		},
	},
};
</script>

<style lang="postcss">
.d-content-page-custom .c-long-read {
	max-height: calc(100vh - 2 * var(--theme-spacing-lg));
}
</style>
